export const translations = {
  hr: {
    hero: {
      title: "Kontaktirajte Nas",
      description: "Javite nam se za više informacija o našim uslugama ili za dogovor o suradnji."
    },
    contact: {
      title: "Pošaljite Poruku",
      description: "Ispunite obrazac i javit ćemo vam se u najkraćem mogućem roku.",
      form: {
        name: "Ime i prezime",
        email: "Email",
        phone: "Telefon",
        message: "Poruka",
        button: "Pošalji poruku",
        placeholders: {
          name: "Unesite vaše ime i prezime",
          email: "Unesite vašu email adresu",
          phone: "Unesite vaš broj telefona",
          message: "Unesite vašu poruku..."
        }
      }
    },
    info: {
      title: "Informacije",
      description: "Pronađite nas na sljedećim lokacijama i kontakt podacima.",
      email: "Email",
      phone: "Telefon",
      address: {
        title: "Adresa",
        street: "Ante Starčevića 22",
        city: "Pleternica",
        country: "Hrvatska"
      },
      contact: {
        title: "Kontakt",
        email: "info@microplet.hr",
        phone: "+385 42 260 099",
        fax: "+385 42 260 099"
      },
      hours: {
        title: "Radno vrijeme",
        weekdays: "Ponedjeljak - Petak",
        weekdayHours: "08:00 - 16:00",
        saturday: "Subota",
        sunday: "Nedjelja",
        weekend: "Subota - Nedjelja",
        weekendHours: "Zatvoreno"
      },
      persons: {
        title: "Kontakt osobe",
        director: "Direktor",
        accounting: "Računovodstvo",
        procurement: "Nabava",
        technical: {
          title: "Tehnička priprema-inžinjeri",
          foreman: "Programer-Poslovođa",
          programmer: "Programer-Konstruktor",
          controller: "Programer-Kontrolor"
        }
      }
    }
  },
  en: {
    hero: {
      title: "Contact Us",
      description: "Get in touch with us for more information about our services or to discuss collaboration."
    },
    contact: {
      title: "Send an Inquiry",
      description: "Fill out the form and we will get back to you as soon as possible.",
      form: {
        name: "Full name",
        email: "Email address",
        phone: "Phone number",
        message: "Your message",
        button: "Send message",
        placeholders: {
          name: "Enter your full name",
          email: "Enter your email address",
          phone: "Enter your phone number",
          message: "Enter your message..."
        }
      }
    },
    info: {
      title: "Information",
      description: "Find us at the following locations and contact details.",
      email: "Email",
      phone: "Phone",
      address: {
        title: "Address",
        street: "Ante Starčevića 22",
        city: "34310 Pleternica",
        country: "Croatia"
      },
      contact: {
        title: "Contact",
        phone: "+385 42 260 099",
        fax: "+385 42 260 099"
      },
      hours: {
        title: "Working Hours",
        weekdays: "Monday - Friday",
        weekdayHours: "08:00 - 16:00",
        saturday: "Saturday",
        sunday: "Sunday",
        weekend: "Saturday - Sunday",
        weekendHours: "Closed"
      },
      persons: {
        title: "Contact Persons",
        director: "Director",
        accounting: "Accounting",
        procurement: "Procurement",
        technical: {
          title: "Technical Preparation - Engineers",
          foreman: "Programmer-Supervisor",
          programmer: "Programmer-Constructor",
          controller: "Programmer-Controller"
        }
      }
    }
  },
  de: {
    hero: {
      title: "Kontaktieren Sie uns",
      description: "Kontaktieren Sie uns für weitere Informationen über unsere Dienstleistungen oder um eine Zusammenarbeit zu besprechen."
    },
    contact: {
      title: "Anfrage senden",
      description: "Füllen Sie das Formular aus und wir melden uns schnellstmöglich bei Ihnen.",
      form: {
        name: "Vollständiger Name",
        email: "E-Mail",
        phone: "Telefonnummer",
        message: "Ihre Nachricht",
        button: "Nachricht senden",
        placeholders: {
          name: "Geben Sie Ihren vollständigen Namen ein",
          email: "Geben Sie Ihre E-Mail-Adresse ein",
          phone: "Geben Sie Ihre Telefonnummer ein",
          message: "Geben Sie Ihre Nachricht ein..."
        }
      }
    },
    info: {
      title: "Informationen",
      description: "Finden Sie uns unter folgenden Adressen und Kontaktdaten.",
      email: "E-mail",
      phone: "Telefon",
      address: {
        title: "Adresse",
        street: "Ante Starčevića 22",
        city: "Pleternica",
        country: "Kroatien"
      },
      contact: {
        title: "Kontakt",
        email: "info@microplet.hr",
        phone: "+385 42 260 099",
        fax: "+385 42 260 099"
      },
      hours: {
        title: "Öffnungszeiten",
        weekdays: "Montag - Freitag",
        weekdayHours: "08:00 - 16:00",
        saturday: "Samstag",
        sunday: "Sonntag",
        weekend: "Samstag - Sonntag",
        weekendHours: "Geschlossen"
      },
      persons: {
        title: "Kontaktpersonen",
        director: "Geschäftsführer",
        accounting: "Buchhaltung",
        procurement: "Beschaffung",
        technical: {
          title: "Technische Vorbereitung - Ingenieure",
          foreman: "Programmierer-Vorarbeiter",
          programmer: "Programmierer-Konstruktor",
          controller: "Programmierer-Prüfer"
        }
      }
    }
  }
}; 