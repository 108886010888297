import React from 'react';
import { useLanguage } from '../contexts/LanguageContext';
import { translations } from '../translations/legal';
import Transition from '../components/Transition';

const Impressum = () => {
  const { language } = useLanguage();
  const t = translations[language].impressum;

  return (
    <div className="min-h-screen bg-dark-900 text-white relative overflow-hidden">
      {/* Background Effects */}
      <div className="fixed inset-0 z-0">
        <div className="absolute top-0 right-0 w-[840px] h-[840px] bg-[radial-gradient(circle_at_center,rgba(255,94,7,0.12)_0%,transparent_70%)] rounded-full animate-pulse"></div>
        <div className="absolute bottom-0 left-0 w-[1040px] h-[1040px] bg-[radial-gradient(circle_at_center,rgba(255,94,7,0.03)_0%,transparent_70%)] rounded-full animate-pulse delay-1000"></div>
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[840px] h-[840px] bg-[radial-gradient(circle_at_center,rgba(255,94,7,0.05)_0%,transparent_70%)] rounded-full animate-pulse delay-500"></div>
        <div className="absolute inset-0 bg-gradient-to-b from-transparent via-dark-900/50 to-dark-900 animate-pulse"></div>
      </div>

      {/* Content */}
      <div className="relative z-10 pt-40 pb-20">
        <div className="container mx-auto px-4 max-w-4xl">
          <Transition>
            <h1 className="text-4xl md:text-5xl font-bold mb-12">
              <span className="block text-[#d6d6d6] [-webkit-text-fill-color:transparent] bg-[linear-gradient(270deg,#36383c,#fff_54%,#36383c)] [-webkit-background-clip:text] bg-clip-text">
                {t.title}
              </span>
            </h1>
          </Transition>

          {/* Company Information */}
          <Transition delay={0.1}>
            <div className="mb-12">
              <h2 className="text-2xl font-semibold mb-6">{t.company.title}</h2>
              <div className="bg-[#0a0a0b] rounded-3xl p-8">
                <p className="text-xl font-semibold mb-4">{t.company.name}</p>
                <div className="space-y-2 text-gray-400">
                  <p>{t.company.address}</p>
                  <p>{t.company.city}</p>
                  <p>{t.company.country}</p>
                  <p className="mt-4">{t.company.registration}</p>
                  <p>{t.company.vatId}</p>
                  <p>{t.company.management}</p>
                </div>
              </div>
            </div>
          </Transition>

          {/* Contact Information */}
          <Transition delay={0.2}>
            <div className="mb-12">
              <h2 className="text-2xl font-semibold mb-6">{t.contact.title}</h2>
              <div className="bg-[#0a0a0b] rounded-3xl p-8">
                <div className="space-y-2 text-gray-400">
                  <p><a href="mailto:microplet@gmail.com">{t.contact.email}</a></p>
                </div>
              </div>
            </div>
          </Transition>

          {/* Legal Information */}
          <Transition delay={0.3}>
            <div className="mb-12">
              <h2 className="text-2xl font-semibold mb-6">{t.legal.title}</h2>
              <div className="bg-[#0a0a0b] rounded-3xl p-8">
                <p className="text-gray-400">{t.legal.content}</p>
              </div>
            </div>
          </Transition>

          {/* Responsibility */}
          <Transition delay={0.4}>
            <div className="mb-12">
              <h2 className="text-2xl font-semibold mb-6">{t.responsibility.title}</h2>
              <div className="bg-[#0a0a0b] rounded-3xl p-8">
                <p className="text-gray-400">{t.responsibility.content}</p>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </div>
  );
};

export default Impressum; 