import React from 'react';
import { 
  ChartBarIcon, 
  UserGroupIcon, 
  Cog6ToothIcon,
  CheckCircleIcon,
  FireIcon,
  BoltIcon,
  CubeIcon
} from '@heroicons/react/24/outline';
import Transition from '../components/Transition';
import { useLanguage } from '../contexts/LanguageContext';
import { translations } from '../translations/about';
import { translations as homeTranslations } from '../translations/home';
import SEO from '../components/SEO';

const About = () => {
  const { language } = useLanguage();
  const t = translations[language];
  const tHome = homeTranslations[language];
  const seoData = {
    hr: {
      title: 'Microplet - Vodeći proizvođač u laserskom rezanju metala',
      description: 'Specijalizirani za lasersko rezanje metala i obrada plastike. Visokokvalitetna proizvodnja i stručno savjetovanje za vaše projekte.'
    },
    en: {
      title: 'Microplet - Leading Manufacturer in Metal Laser Cutting',
      description: 'Specialized in metal laser cutting and plastic processing. High-quality production and expert consulting for your projects.'
    },
    de: {
      title: 'Microplet - Führender Hersteller im Metall-Laserschneiden',
      description: 'Spezialisiert auf Metall-Laserschneiden und Kunststoffverarbeitung. Hochwertige Produktion und fachkundige Beratung für Ihre Projekte.'
    }
  };

  return (
    <>
      <SEO {...seoData[language]} />
      <div className="min-h-screen bg-dark-900 text-white relative overflow-hidden">
        {/* Background Effects */}
        <div className="fixed inset-0 z-0">
          {/* Animated gradient circles */}
          <div className="absolute top-0 right-0 w-[840px] h-[840px] bg-[radial-gradient(circle_at_center,rgba(255,94,7,0.12)_0%,transparent_70%)] md:bg-[radial-gradient(circle_at_center,rgba(255,94,7,0.12)_0%,transparent_70%)] rounded-full animate-pulse"></div>
          <div className="absolute bottom-0 left-0 w-[1040px] h-[1040px] bg-[radial-gradient(circle_at_center,rgba(255,94,7,0.03)_0%,transparent_70%)] md:bg-[radial-gradient(circle_at_center,rgba(255,94,7,0.08)_0%,transparent_70%)] rounded-full animate-pulse delay-1000"></div>
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[840px] h-[840px] bg-[radial-gradient(circle_at_center,rgba(255,94,7,0.05)_0%,transparent_70%)] md:bg-[radial-gradient(circle_at_center,rgba(255,94,7,0.1)_0%,transparent_70%)] rounded-full animate-pulse delay-500"></div>
          
          {/* Animated gradient overlay */}
          <div className="absolute inset-0 bg-gradient-to-b from-transparent via-dark-900/50 to-dark-900 md:via-dark-900/30 animate-pulse"></div>
        </div>

        {/* Content wrapper with z-index to appear above background */}
        <div className="relative z-10">
      {/* Hero Section */}
          <section className="relative pt-60 pb-52 overflow-hidden">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto text-center">
            <Transition
              show={true}
              enter="transition-all duration-1000"
              enterFrom="opacity-0 transform translate-y-8"
              enterTo="opacity-100 transform translate-y-0"
            >
              <h1 className="text-4xl md:text-6xl font-bold mb-6">
                    <span className="block pb-2 text-[#d6d6d6] [-webkit-text-fill-color:transparent] bg-[linear-gradient(270deg,#36383c,#fff_54%,#36383c)] [-webkit-background-clip:text] bg-clip-text">
                      {t.hero.title}
                </span>
              </h1>
              <p className="text-xl text-gray-400 mb-8">
                  {t.hero.description}
              </p>
            </Transition>
          </div>
        </div>
      </section>

      {/* Company Story Section */}
      <section className="py-20">
        <div className="relative container mx-auto px-4 xl:max-w-[80%]">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            <div>
                  <Transition delay={0.2}>
                    <div className="mb-4">
                      <span className="text-laser text-sm font-medium tracking-wider">
                        {t.story.label}
                      </span>
                    </div>
                  </Transition>

                  <Transition delay={0.3}>
                  <h2 className="text-left max-w-4xl mb-6">
                    <span className="text-[28px] md:text-[56px] font-[500] leading-[32px] md:leading-[64px] font-['Inter']">
                      <span className="block text-[#d6d6d6] [-webkit-text-fill-color:transparent] bg-[linear-gradient(270deg,#36383c,#fff_54%,#36383c)] [-webkit-background-clip:text] bg-clip-text">{t.story.title}</span>
                    </span>
                  </h2>
                  </Transition>

                  <Transition delay={0.4}>
                    <p className="text-gray-400 mb-6 text-lg">
                      {t.story.description1}
                    </p>
                    <p className="text-gray-400 text-lg">
                      {t.story.description2}
                    </p>
                    <p className="text-gray-400 text-lg">
                      {t.story.description3}
                    </p>
                  </Transition>
            </div>
                <Transition delay={0.5}>
                  <div className="relative group">
                    <div className="absolute inset-0 bg-gradient-to-r from-laser/20 to-[#FF8A3C]/20 rounded-3xl blur-xl group-hover:blur-2xl transition-all duration-700"></div>
                    <div className="relative aspect-w-16 aspect-h-9 rounded-3xl overflow-hidden">
                <img 
                  src="images/microplet-crew.jpeg" 
                  alt="Proizvodni proces" 
                        className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-700"
                />
              </div>
            </div>
                </Transition>
          </div>
        </div>
      </section>

      {/* Values Section */}
          <div className="relative py-32">
            <div className="relative container mx-auto px-4 xl:max-w-[80%]">
              <Transition delay={0.2}>
                <div className="mb-4">
                  <span className="text-laser text-sm font-medium tracking-wider">
                    {t.values.title}
                  </span>
                </div>
              </Transition>

              <div className="flex flex-col lg:flex-row justify-between items-start mb-16">
                <Transition delay={0.3}>
                  <h2 className="text-left max-w-4xl">
                    <span className="text-[28px] md:text-[56px] font-[500] leading-[32px] md:leading-[64px] font-['Inter']">
                      <span className="block text-[#d6d6d6] [-webkit-text-fill-color:transparent] bg-[linear-gradient(270deg,#36383c,#fff_54%,#36383c)] [-webkit-background-clip:text] bg-clip-text">{t.values.subtitle}</span>
                      <span className="block text-[#d6d6d6] [-webkit-text-fill-color:transparent] bg-[linear-gradient(270deg,#36383c,#fff_54%,#36383c)] [-webkit-background-clip:text] bg-clip-text">{t.values.description}</span>
                    </span>
                  </h2>
                </Transition>

                <Transition delay={0.4}>
                  <p className="text-gray-400 text-lg mt-6 lg:mt-0 lg:text-right max-w-sm">
                    {t.values.intro}
                  </p>
                </Transition>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-24">
                {/* Value 1 */}
                <Transition delay={0.5}>
                  <div className="group h-full bg-[#0a0a0b] hover:bg-[conic-gradient(from_140.06deg_at_56.99%_57.62%,#FFFFFF_-135.71deg,#A8A8A8_3.39deg,#FFFFFF_224.29deg,#A8A8A8_363.39deg)] backdrop-blur-sm rounded-3xl p-8 transition-all duration-700 ease-in-out hover:scale-[1.02]">
                    <div className="h-16 w-16 rounded-full bg-white/10 group-hover:bg-dark-900/10 flex items-center justify-center mb-8 transition-all duration-700 ease-in-out">
                      <ChartBarIcon className="h-10 w-10 text-white group-hover:text-dark-900 transition-all duration-700" />
                    </div>
                    <h3 className="text-2xl font-semibold text-white group-hover:text-dark-900 mb-4 transition-all duration-700">
                      {t.values.items[0].title}
                    </h3>
                    <p className="text-gray-400 group-hover:text-dark-900 mb-6 transition-all duration-700">
                      {t.values.items[0].description}
                    </p>
                    <p className="text-sm text-gray-500 group-hover:text-dark-900 transition-all duration-700">
                      {t.values.items[0].details}
              </p>
            </div>
                </Transition>

                {/* Value 2 */}
                <Transition delay={0.6}>
                  <div className="group h-full bg-[conic-gradient(from_140.06deg_at_56.99%_57.62%,#FFFFFF_-135.71deg,#A8A8A8_3.39deg,#FFFFFF_224.29deg,#A8A8A8_363.39deg)] backdrop-blur-sm rounded-3xl p-8 transition-all duration-700 ease-in-out hover:scale-[1.02]">
                    <div className="h-16 w-16 rounded-full bg-dark-900/10 flex items-center justify-center mb-8 transition-all duration-700 ease-in-out">
                      <UserGroupIcon className="h-10 w-10 text-dark-900 transition-all duration-700 ease-in-out" />
                    </div>
                    <h3 className="text-2xl font-semibold text-dark-900 mb-4 transition-all duration-700 ease-in-out">
                      {t.values.items[1].title}
                    </h3>
                    <p className="text-dark-900 mb-6 transition-all duration-700 ease-in-out">
                      {t.values.items[1].description}
                    </p>
                    <p className="text-sm text-dark-900 transition-all duration-700 ease-in-out">
                      {t.values.items[1].details}
              </p>
            </div>
                </Transition>

                {/* Value 3 */}
                <Transition delay={0.7}>
                  <div className="group h-full bg-[#0a0a0b] hover:bg-[conic-gradient(from_140.06deg_at_56.99%_57.62%,#FFFFFF_-135.71deg,#A8A8A8_3.39deg,#FFFFFF_224.29deg,#A8A8A8_363.39deg)] backdrop-blur-sm rounded-3xl p-8 transition-all duration-700 ease-in-out hover:scale-[1.02]">
                    <div className="h-16 w-16 rounded-full bg-white/10 group-hover:bg-dark-900/10 flex items-center justify-center mb-8 transition-all duration-700 ease-in-out">
                      <Cog6ToothIcon className="h-10 w-10 text-white group-hover:text-dark-900 transition-all duration-700" />
                    </div>
                    <h3 className="text-2xl font-semibold text-white group-hover:text-dark-900 mb-4 transition-all duration-700">
                      {t.values.items[2].title}
                    </h3>
                    <p className="text-gray-400 group-hover:text-dark-900 mb-6 transition-all duration-700">
                      {t.values.items[2].description}
                    </p>
                    <p className="text-sm text-gray-500 group-hover:text-dark-900 transition-all duration-700">
                      {t.values.items[2].details}
                    </p>
                  </div>
                </Transition>
              </div>
            </div>
          </div>

           {/* Features Section */}
           <div className="relative py-32">
              <div className="relative container mx-auto px-4 xl:max-w-[80%]">
                <Transition delay={0.2}>
                  <div className="mb-4">
                    <span className="text-laser text-sm font-medium tracking-wider">
                      {t.expertise.title}
                    </span>
                  </div>
                </Transition>

                <div className="flex flex-col lg:flex-row justify-between items-start mb-16">
                  <Transition delay={0.3}>
                    <h2 className="text-left max-w-4xl">
                      <span className="text-[28px] md:text-[56px] font-[500] leading-[32px] md:leading-[64px] font-['Inter']">
                        <span className="block text-[#d6d6d6] [-webkit-text-fill-color:transparent] bg-[linear-gradient(270deg,#36383c,#fff_54%,#36383c)] [-webkit-background-clip:text] bg-clip-text">{t.expertise.subtitle}</span>
                        <span className="block text-[#d6d6d6] [-webkit-text-fill-color:transparent] bg-[linear-gradient(270deg,#36383c,#fff_54%,#36383c)] [-webkit-background-clip:text] bg-clip-text">{t.expertise.quality}</span>
                      </span>
                    </h2>
                  </Transition>

                  <Transition delay={0.4}>
                    <p className="text-gray-400 text-lg mt-6 lg:mt-0 lg:text-right max-w-sm">
                      {t.expertise.details}
                    </p>
                  </Transition>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-24">
                  {/* Feature 1 */}
                  <Transition delay={0.5}>
                    <div className="group h-full bg-[#0a0a0b] backdrop-blur-sm rounded-3xl p-8 transition-all duration-500 hover:scale-[1.02]">
                      <div className="h-16 w-16 rounded-full bg-white/10 flex items-center justify-center mb-8">
                        <FireIcon className="h-10 w-10 text-white" />
                      </div>
                      <h3 className="text-2xl font-semibold text-white mb-4">
                        {tHome.services.metal.title}
                      </h3>
                      <p className="text-gray-400 mb-6">
                        {tHome.services.metal.description}
                      </p>
                      <p className="text-sm text-gray-500">
                        {tHome.services.metal.details}
                      </p>
                    </div>
                  </Transition>

                  {/* Feature 2 */}
                  <Transition delay={0.6}>
                    <div className="group h-full bg-[conic-gradient(from_140.06deg_at_56.99%_57.62%,#FFFFFF_-135.71deg,#A8A8A8_3.39deg,#FFFFFF_224.29deg,#A8A8A8_363.39deg)] backdrop-blur-sm rounded-3xl p-8 border border-laser/20 hover:border-laser/30 transition-all duration-500 hover:scale-[1.02]">
                      <div className="h-16 w-16 rounded-full bg-dark-900/10 flex items-center justify-center mb-8">
                        <BoltIcon className="h-10 w-10 text-dark-900" />
                      </div>
                      <h3 className="text-2xl font-semibold text-dark-900 mb-4">
                        {tHome.services.laser.title}
                      </h3>
                      <p className="text-dark-900 mb-6">
                        {tHome.services.laser.description}
                      </p>
                      <p className="text-sm text-dark-900">
                        {tHome.services.laser.details}
                      </p>
                    </div>
                  </Transition>

                  {/* Feature 3 */}
                  <Transition delay={0.7}>
                    <div className="group h-full bg-[#0a0a0b] backdrop-blur-sm rounded-3xl p-8 transition-all duration-500 hover:scale-[1.02]">
                      <div className="h-16 w-16 rounded-full bg-white/10 flex items-center justify-center mb-8">
                        <CubeIcon className="h-10 w-10 text-white" />
                      </div>
                      <h3 className="text-2xl font-semibold text-white mb-4">
                        {tHome.services.tools.title}
                      </h3>
                      <p className="text-gray-400 mb-6">
                        {tHome.services.tools.description}
                      </p>
                      <p className="text-sm text-gray-500">
                        {tHome.services.tools.details}
                      </p>
                    </div>
                  </Transition>
            </div>
          </div>
        </div>

      {/* Expertise Section */}
      <section className="py-20">
        <div className="container mx-auto px-4">
              <h2 className="text-3xl font-bold text-center mb-12">{t.expertise.title}</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="bg-[#0a0a0b] p-8 rounded-3xl">
                  <h3 className="text-xl font-semibold mb-4">{t.services.laser.title}</h3>
              <p className="text-gray-400 mb-4">
                    {t.services.laser.description}
              </p>
              <ul className="space-y-2">
                    {t.services.laser.features.map((feature, index) => (
                      <li key={index} className="flex items-center text-gray-400">
                  <CheckCircleIcon className="h-5 w-5 text-laser mr-2" />
                        {feature}
                </li>
                    ))}
              </ul>
            </div>
            <div className="bg-[#0a0a0b] p-8 rounded-3xl">
                  <h3 className="text-xl font-semibold mb-4">{t.services.products.title}</h3>
              <p className="text-gray-400 mb-4">
                    {t.services.products.description}
              </p>
              <ul className="space-y-2">
                    {t.services.products.features.map((feature, index) => (
                      <li key={index} className="flex items-center text-gray-400">
                  <CheckCircleIcon className="h-5 w-5 text-laser mr-2" />
                        {feature}
                </li>
                    ))}
              </ul>
            </div>
            <div className="bg-[#0a0a0b] p-8 rounded-3xl">
                  <h3 className="text-xl font-semibold mb-4">{t.services.tools.title}</h3>
              <p className="text-gray-400 mb-4">
                    {t.services.tools.description}
              </p>
              <ul className="space-y-2">
                    {t.services.tools.features.map((feature, index) => (
                      <li key={index} className="flex items-center text-gray-400">
                  <CheckCircleIcon className="h-5 w-5 text-laser mr-2" />
                        {feature}
                </li>
                    ))}
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-20 bg-[#0a0a0b]">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto text-center">
                <h2 className="text-3xl font-bold mb-6">{t.cta.title}</h2>
            <p className="text-xl text-gray-400 mb-8">
                  {t.cta.description}
            </p>
            <a
              href="/contact"
              className="inline-flex items-center px-8 py-3 bg-gradient-to-r from-laser to-[#FF8A3C] text-white text-lg rounded-full transition-all duration-300 hover:shadow-[0_0_25px_rgba(255,94,7,0.35)] hover:scale-[1.02]"
            >
                  <span>{t.cta.button}</span>
              <span className="ml-2 transform transition-transform duration-300 group-hover:translate-x-1">→</span>
            </a>
          </div>
        </div>
      </section>
    </div>
      </div>
    </>
  );
};

export default About; 