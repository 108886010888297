export const translations = {
  hr: {
    hero: {
      title: "Vrhunska Preciznost",
      subtitle: "Lasersko Rezanje Materijala",
      description: "Obrada Metala i Plastike",
      cta: "Kontaktirajte Nas",
      secondaryCta: "Saznajte Više"
    },
    expertise1: {
      title: "Stručnjaci za Obradu Metala i Plastike",
      subtitle: "Obrada Metala i Plastike.",
      quality: "Vrhunska Kvaliteta i Preciznost.",
      details: "Specijalizirani smo za obradu metala, lasersko rezanje, zavarivanje te obradu plastike i savijanje."
    },
    services: {
      metal: {
        title: "Obrada metala",
        description: "Profesionalne usluge obrade metala s najmodernijom opremom i stručnim timom.",
        details: "Glodanje, tokarenje, erodiranje (žičano i utopno), brušenje (ravno i okruglo), štancanje na ekscentar preši, toplinska obrada - kaljenje"
      },
      laser: {
        title: "Lasersko rezanje i zavarivanje",
        description: "Precizno lasersko rezanje i profesionalno zavarivanje različitih materijala.",
        details: "Lasersko rezanje aluminija, inox-a i crnog materijala. Zavarivanje aluminija, inox-a i crnog materijala, te robotsko zavarivanje."
      },
      tools: {
        title: "Obrada plastike i savijanje",
        description: "Specijalizirane usluge obrade plastike i savijanja metala.",
        details: "Brizganje plastike, savijanje na apkant preši i precizna obrada materijala."
      }
    },
    solutions: {
      title: "Napredna Rješenja",
      subtitle: "Lasersko Rezanje Materijala",
      description: "Vrhunska Kvaliteta i Preciznost",
      mainDescription: "Eliminirajte neučinkovitosti s naprednim rješenjima koja automatiziraju procese, smanjuju ručni rad i osiguravaju besprijekoran rad vaše proizvodnje.",
      items: [
        {
          title: "Napredna Proizvodnja",
          description: "Automatizirana proizvodnja alata s preciznim praćenjem inventara i kontrolom kvalitete u stvarnom vremenu."
        },
        {
          title: "Precizno Planiranje",
          description: "Optimizirajte proizvodnju s naprednim planiranjem temeljenim na stvarnim podacima i predviđanjima."
        },
        {
          title: "Kontrola Kvalitete",
          description: "Sustav kontrole kvalitete koji osigurava vrhunsku preciznost i pouzdanost svakog proizvedenog alata."
        },
        {
          title: "Inovativna Rješenja",
          description: "Kontinuirano unapređujemo naše procese i tehnologije kako bismo vam pružili najbolja rješenja."
        },
        {
          title: "Brza Isporuka",
          description: "Optimizirani proizvodni procesi koji omogućuju brzu izradu i isporuku vaših alata."
        },
        {
          title: "Stručna Podrška",
          description: "Profesionalna tehnička podrška i savjetovanje za optimalna rješenja vaših projekata."
        }
      ]
    },
    gallery: {
      title: "Galerija",
      subtitle: "Galerija Projekata",
      description: "Pregledajte preciznost, snagu i kreativnost kroz slike naših procesa – od laserskog rezanja do preciznog tokarenja i brizganja.",
      cta: "Pogledajte našu galeriju",
      
      descriptions: {
        laser: {
          hr: 'Precizno lasersko rezanje metala s najmodernijom tehnologijom',
          en: 'Precision metal laser cutting with state-of-the-art technology',
          de: 'Präzises Laserschneiden von Metall mit modernster Technologie'
        },
        tokarenje: {
          hr: 'Profesionalno tokarenje i obrada metala',
          en: 'Professional metal turning and processing',
          de: 'Professionelles Metalldrehen und -verarbeitung'
        },
        alati: {
          hr: 'Izrada specijaliziranih alata po mjeri',
          en: 'Custom specialized tool manufacturing',
          de: 'Herstellung spezialisierter Werkzeuge nach Maß'
        },
        glodalice: {
          hr: 'Precizna obrada na CNC glodalicama',
          en: 'Precision CNC milling operations',
          de: 'Präzise CNC-Fräsbearbeitung'
        },
        savijanje: {
          hr: 'Savijanje metala prema specifikacijama',
          en: 'Metal bending according to specifications',
          de: 'Metallbiegen nach Spezifikationen'
        },
        brizganje: {
          hr: 'Brizganje plastike i izrada kalupa',
          en: 'Plastic injection molding and mold making',
          de: 'Kunststoffspritzguss und Formenbau'
        },
        rezanje: {
          hr: 'Precizno rezanje različitih materijala',
          en: 'Precision cutting of various materials',
          de: 'Präzisionsschneiden verschiedener Materialien'
        },
        varenje: {
          hr: 'Profesionalno zavarivanje i spajanje metala',
          en: 'Professional welding and metal joining',
          de: 'Professionelles Schweißen und Metallverbindung'
        }
      }
    },
    partners: {
      title: 'Naši Partneri',
      subtitle: 'Suradnja i povjerenje'
    },
    expertise: {
      title: "Iskustvo",
      subtitle: "Više od 30 godina stručnosti u metalu i plastici.",
      details: "Kombiniramo desetljeća iskustva s najnovijim tehnologijama kako bismo vam pružili vrhunska rješenja za vaše proizvodne potrebe."
    },
    process: {
      title: "Proces Proizvodnje",
      subtitle: "Industrijska Tehnologija",
      steps: [
        {
          number: "01",
          title: "Stručno Savjetovanje",
          description: "Naši stručnjaci analiziraju vaše specifične potrebe i predlažu optimalna rješenja za vaše proizvodne procese, osiguravajući maksimalnu učinkovitost i povrat ulaganja.",
          cta: "Kontaktirajte Nas"
        },
        {
          number: "02",
          title: "Prilagođena Rješenja",
          description: "Svaki alat je dizajniran prema vašim specifičnim zahtjevima, uzimajući u obzir vaše proizvodne procese, materijale i ciljeve. Pružamo personalizirana rješenja koja odgovaraju vašim potrebama.",
          cta: "Kontaktirajte Nas"
        },
        {
          number: "03",
          title: "Kontinuirana Podrška",
          description: "Pružamo kontinuiranu tehničku podršku i održavanje kroz cijeli životni ciklus alata. Naši stručnjaci su uvijek dostupni za savjete i rješavanje izazova.",
          cta: "Kontaktirajte Nas"
        }
      ]
    },
    contact: {
      title: "Kontaktirajte Nas",
      subtitle: "",
      description: "Spremni smo pomoći vam u realizaciji vaših projekata. Kontaktirajte nas i dogovorite konzultacije s našim stručnjacima.",
      info: {
        email: "Email",
        emailValue: "info@microplet.hr",
        phone: "Telefon",
        phoneValue: "+385 1 2345 678",
        address: "Adresa",
        addressValue: "Zagreb, Hrvatska"
      },
      form: {
        name: "Ime i Prezime",
        namePlaceholder: "Unesite vaše ime",
        email: "Email Adresa",
        emailPlaceholder: "vas@email.com",
        phone: "Telefon",
        phonePlaceholder: "+385",
        message: "Vaša Poruka",
        messagePlaceholder: "Opišite vaš projekt...",
        submit: "Pošaljite Upit"
      }
    }
  },
  en: {
    hero: {
      title: "Unmatched Precision",
      subtitle: "Laser Material Cutting",
      description: "Metal and Plastic Processing",
      cta: "Contact Us",
      secondaryCta: "Learn More"
    },
    expertise1: {
      title: "Experts in Metal and Plastic Processing",
      subtitle: "Metal and Plastic Processing.",
      quality: "Premium Quality and Precision.",
      details: "We specialize in metal processing, laser cutting, welding, as well as plastic processing and bending."
    },
    services: {
      metal: {
        title: "Metal Processing",
        description: "Professional metal processing services with state-of-the-art equipment and expert team.",
        details: "Milling, turning, wire and die-sink EDM, grinding (flat and cylindrical), eccentric press stamping, heat treatment - hardening"
      },
      laser: {
        title: "Laser Cutting and Welding",
        description: "Precise laser cutting and professional welding of various materials.",
        details: "Laser cutting of aluminum, stainless steel, and black steel. Welding of aluminum, stainless steel, and black steel, including robotic welding."
      },
      tools: {
        title: "Plastic Processing and Bending",
        description: "Specialized plastic processing and metal bending services.",
        details: "Plastic injection molding, press brake bending, and precision material processing."
      }
    },
    solutions: {
      title: "Advanced Solutions",
      subtitle: "Laser Material Cutting",
      description: "Premium Quality and Precision",
      mainDescription: "Eliminate inefficiencies with advanced solutions that automate processes, reduce manual work, and ensure flawless operation of your production.",
      items: [
        {
          title: "Advanced Manufacturing",
          description: "Automated tool production with precise inventory tracking and real-time quality control."
        },
        {
          title: "Precise Planning",
          description: "Optimize production with advanced planning based on real data and forecasts."
        },
        {
          title: "Quality Control",
          description: "Quality control system ensuring premium precision and reliability of every tool produced."
        },
        {
          title: "Innovative Solutions",
          description: "We continuously improve our processes and technologies to provide you with the best solutions."
        },
        {
          title: "Fast Delivery",
          description: "Optimized production processes enabling fast manufacturing and delivery of your tools."
        },
        {
          title: "Expert Support",
          description: "Professional technical support and consulting for optimal solutions for your projects."
        }
      ]
    },
    gallery: {
      title: "Gallery",
      subtitle: "Project Gallery",
      description: "Explore the precision, power, and creativity through images of our processes – from laser cutting to precise turning and injection molding.",
      cta: "View our gallery",
      descriptions: {
        laser: {
          hr: 'Precizno lasersko rezanje metala s najmodernijom tehnologijom',
          en: 'Precision metal laser cutting with state-of-the-art technology',
          de: 'Präzises Laserschneiden von Metall mit modernster Technologie'
        },
        tokarenje: {
          hr: 'Profesionalno tokarenje i obrada metala',
          en: 'Professional metal turning and processing',
          de: 'Professionelles Metalldrehen und -verarbeitung'
        },
        alati: {
          hr: 'Izrada specijaliziranih alata po mjeri',
          en: 'Custom specialized tool manufacturing',
          de: 'Herstellung spezialisierter Werkzeuge nach Maß'
        },
        glodalice: {
          hr: 'Precizna obrada na CNC glodalicama',
          en: 'Precision CNC milling operations',
          de: 'Präzise CNC-Fräsbearbeitung'
        },
        savijanje: {
          hr: 'Savijanje metala prema specifikacijama',
          en: 'Metal bending according to specifications',
          de: 'Metallbiegen nach Spezifikationen'
        },
        brizganje: {
          hr: 'Brizganje plastike i izrada kalupa',
          en: 'Plastic injection molding and mold making',
          de: 'Kunststoffspritzguss und Formenbau'
        },
        rezanje: {
          hr: 'Precizno rezanje različitih materijala',
          en: 'Precision cutting of various materials',
          de: 'Präzisionsschneiden verschiedener Materialien'
        },
        varenje: {
          hr: 'Profesionalno zavarivanje i spajanje metala',
          en: 'Professional welding and metal joining',
          de: 'Professionelles Schweißen und Metallverbindung'
        }
      }
    },
    partners: {
      title: 'Our Partners',
      subtitle: 'Collaboration and Trust'
    },
    expertise: {
      title: "Experience",
      subtitle: "More than 30 years of expertise in metal and plastic processing.",
      details: "We combine decades of experience with the latest technologies to provide you with premium solutions for your production needs."
    },
    process: {
      title: "Production Process",
      subtitle: "Industrial Technology",
      steps: [
        {
          number: "01",
          title: "Expert Consulting",
          description: "Our experts analyze your specific needs and propose optimal solutions for your production processes, ensuring maximum efficiency and return on investment.",
          cta: "Contact Us"
        },
        {
          number: "02",
          title: "Custom Solutions",
          description: "Each tool is designed according to your specific requirements, taking into account your production processes, materials, and goals. We provide personalized solutions that meet your needs.",
          cta: "Contact Us"
        },
        {
          number: "03",
          title: "Continuous Support",
          description: "We provide continuous technical support and maintenance throughout the entire tool lifecycle. Our experts are always available for advice and solving challenges.",
          cta: "Contact Us"
        }
      ]
    },
    contact: {
      title: "Contact Us",
      subtitle: "",
      description: "We're ready to help you realize your projects. Contact us and schedule consultations with our experts.",
      info: {
        email: "Email",
        emailValue: "info@microplet.hr",
        phone: "Phone",
        phoneValue: "+385 1 2345 678",
        address: "Address",
        addressValue: "Zagreb, Croatia"
      },
      form: {
        name: "Full Name",
        namePlaceholder: "Enter your name",
        email: "Email Address",
        emailPlaceholder: "your@email.com",
        phone: "Phone",
        phonePlaceholder: "+385",
        message: "Your Message",
        messagePlaceholder: "Describe your project...",
        submit: "Send Message"
      }
    }
  },
  de: {
    hero: {
      title: "Unübertroffene Präzision",
      subtitle: "Lasermaterialbearbeitung",
      description: "Metall- und Kunststoffverarbeitung",
      cta: "Kontaktieren Sie Uns",
      secondaryCta: "Mehr Erfahren"
    },
    expertise1: {
      title: "Experten für Metall- und Kunststoffverarbeitung",
      subtitle: "Metall- und Kunststoffverarbeitung.",
      quality: "Premium Qualität und Präzision.",
      details: "Wir sind auf Metallbearbeitung, Laserschneiden, Schweißen sowie Kunststoffverarbeitung und Biegen spezialisiert."
    },
    services: {
      metal: {
        title: "Metallbearbeitung",
        description: "Professionelle Metallbearbeitungsdienste mit modernster Ausrüstung und Expertenteam.",
        details: "Fräsen, Drehen, Drahterodieren und Senkerodieren, Schleifen (Flach- und Rundschleifen), Exzenterpressenstanzen, Wärmebehandlung - Härten"
      },
      laser: {
        title: "Laserschneiden und Schweißen",
        description: "Präzises Laserschneiden und professionelles Schweißen verschiedener Materialien.",
        details: "Laserschneiden von Aluminium, Edelstahl und Schwarzstahl. Schweißen von Aluminium, Edelstahl und Schwarzstahl, einschließlich Roboterschweißen."
      },
      tools: {
        title: "Kunststoffverarbeitung und Biegen",
        description: "Spezialisierte Kunststoffverarbeitung und Metallbiegedienste.",
        details: "Kunststoffspritzguss, Abkantpressen und präzise Materialverarbeitung."
      }
    },
    solutions: {
      title: "Fortschrittliche Lösungen",
      subtitle: "Lasermaterialbearbeitung",
      description: "Premium Qualität und Präzision",
      mainDescription: "Eliminieren Sie Ineffizienzen mit fortschrittlichen Lösungen, die Prozesse automatisieren, manuelle Arbeit reduzieren und einen fehlerfreien Betrieb Ihrer Produktion gewährleisten.",
      items: [
        {
          title: "Fortschrittliche Fertigung",
          description: "Automatisierte Werkzeugproduktion mit präziser Bestandsverfolgung und Echtzeit-Qualitätskontrolle."
        },
        {
          title: "Präzise Planung",
          description: "Optimieren Sie die Produktion mit fortschrittlicher Planung basierend auf realen Daten und Prognosen."
        },
        {
          title: "Qualitätskontrolle",
          description: "Qualitätskontrollsystem, das Premium-Präzision und Zuverlässigkeit jedes produzierten Werkzeugs gewährleistet."
        },
        {
          title: "Innovative Lösungen",
          description: "Wir verbessern kontinuierlich unsere Prozesse und Technologien, um Ihnen die besten Lösungen zu bieten."
        },
        {
          title: "Schnelle Lieferung",
          description: "Optimierte Produktionsprozesse für schnelle Herstellung und Lieferung Ihrer Werkzeuge."
        },
        {
          title: "Experten-Support",
          description: "Professionelle technische Unterstützung und Beratung für optimale Lösungen für Ihre Projekte."
        }
      ]
    },
    gallery: {
      title: "Galerie",
      subtitle: "Projektgalerie",
      description: "Erleben Sie Präzision, Kraft und Kreativität in Bildern unserer Prozesse – vom Laserschneiden über präzises Drehen bis hin zum Spritzgießen.",
      cta: "Sehen Sie unsere Galerie",
      descriptions: {
        laser: {
          hr: 'Precizno lasersko rezanje metala s najmodernijom tehnologijom',
          en: 'Precision metal laser cutting with state-of-the-art technology',
          de: 'Präzises Laserschneiden von Metall mit modernster Technologie'
        },
        tokarenje: {
          hr: 'Profesionalno tokarenje i obrada metala',
          en: 'Professional metal turning and processing',
          de: 'Professionelles Metalldrehen und -verarbeitung'
        },
        alati: {
          hr: 'Izrada specijaliziranih alata po mjeri',
          en: 'Custom specialized tool manufacturing',
          de: 'Herstellung spezialisierter Werkzeuge nach Maß'
        },
        glodalice: {
          hr: 'Precizna obrada na CNC glodalicama',
          en: 'Precision CNC milling operations',
          de: 'Präzise CNC-Fräsbearbeitung'
        },
        savijanje: {
          hr: 'Savijanje metala prema specifikacijama',
          en: 'Metal bending according to specifications',
          de: 'Metallbiegen nach Spezifikationen'
        },
        brizganje: {
          hr: 'Brizganje plastike i izrada kalupa',
          en: 'Plastic injection molding and mold making',
          de: 'Kunststoffspritzguss und Formenbau'
        },
        rezanje: {
          hr: 'Precizno rezanje različitih materijala',
          en: 'Precision cutting of various materials',
          de: 'Präzisionsschneiden verschiedener Materialien'
        },
        varenje: {
          hr: 'Profesionalno zavarivanje i spajanje metala',
          en: 'Professional welding and metal joining',
          de: 'Professionelles Schweißen und Metallverbindung'
        }
      }
    },
    partners: {
      title: 'Unsere Partner',
      subtitle: 'Zusammenarbeit und Vertrauen'
    },
    expertise: {
      title: "Erleben Sie",
      subtitle: "Mehr als 30 Jahre Erfahrung in der Metall- und Kunststoffverarbeitung.",
      details: "Wir kombinieren jahrzehntelange Erfahrung mit den neuesten Technologien, um Ihnen Premium-Lösungen für Ihre Produktionsanforderungen zu bieten."
    },
    process: {
      title: "Produktionsprozess",
      subtitle: "Industrietechnologie",
      steps: [
        {
          number: "01",
          title: "Expertenberatung",
          description: "Unsere Experten analysieren Ihre spezifischen Anforderungen und schlagen optimale Lösungen für Ihre Produktionsprozesse vor, die maximale Effizienz und Rendite gewährleisten.",
          cta: "Kontaktieren Sie Uns"
        },
        {
          number: "02",
          title: "Maßgeschneiderte Lösungen",
          description: "Jedes Werkzeug wird nach Ihren spezifischen Anforderungen entwickelt, unter Berücksichtigung Ihrer Produktionsprozesse, Materialien und Ziele. Wir bieten personalisierte Lösungen, die Ihren Bedürfnissen entsprechen.",
          cta: "Kontaktieren Sie Uns"
        },
        {
          number: "03",
          title: "Kontinuierliche Unterstützung",
          description: "Wir bieten kontinuierliche technische Unterstützung und Wartung während des gesamten Werkzeug-Lebenszyklus. Unsere Experten sind immer für Beratung und Problemlösung verfügbar.",
          cta: "Kontaktieren Sie Uns"
        }
      ]
    },
    contact: {
      title: "Kontaktieren Sie uns",
      subtitle: "",
      description: "Wir sind bereit, Ihnen bei der Realisierung Ihrer Projekte zu helfen. Kontaktieren Sie uns und vereinbaren Sie Beratungen mit unseren Experten.",
      info: {
        email: "E-Mail",
        emailValue: "info@microplet.hr",
        phone: "Telefon",
        phoneValue: "+385 1 2345 678",
        address: "Adresse",
        addressValue: "Zagreb, Kroatien"
      },
      form: {
        name: "Vor- und Nachname",
        namePlaceholder: "Geben Sie Ihren Namen ein",
        email: "E-Mail-Adresse",
        emailPlaceholder: "ihre@email.com",
        phone: "Telefon",
        phonePlaceholder: "+385",
        message: "Ihre Nachricht",
        messagePlaceholder: "Beschreiben Sie Ihr Projekt...",
        submit: "Nachricht senden"
      }
    }
  }
}; 