import React, { useState } from 'react';
import { useLanguage } from '../contexts/LanguageContext';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

const LanguageSelector = () => {
  const { language, changeLanguage } = useLanguage();
  const [isOpen, setIsOpen] = useState(false);

  const languages = {
    hr: 'HR',
    en: 'EN',
    de: 'DE'
  };

  const handleLanguageChange = (lang) => {
    changeLanguage(lang);
    setIsOpen(false);
  };

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-dark-800 rounded-lg hover:bg-dark-700 transition-colors duration-200"
      >
        <span>{languages[language]}</span>
        <ChevronDownIcon className="w-4 h-4" />
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-40 bg-dark-800 rounded-lg shadow-lg py-1 z-50">
          {Object.entries(languages).map(([code, name]) => (
            <button
              key={code}
              onClick={() => handleLanguageChange(code)}
              className={`w-full px-4 py-2 text-left text-sm ${
                language === code
                  ? 'text-laser bg-dark-700'
                  : 'text-white hover:bg-dark-700'
              } transition-colors duration-200`}
            >
              {name}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageSelector; 