export const translations = {
  hr: {
    cookieConsent: {
      message: "Koristimo kolačiće kako bismo vam pružili najbolje iskustvo na našoj web stranici.",
      accept: "Prihvati sve",
      decline: "Odbij neobavezne",
      settings: "Postavke kolačića",
      privacyLink: "Pročitajte našu Politiku privatnosti",
      impressumLink: "Impressum"
    },
    privacy: {
      title: "Politika Privatnosti",
      lastUpdated: "Zadnje ažuriranje: 1. ožujka 2024",
      intro: "Vaša privatnost nam je važna. Ova politika privatnosti objašnjava kako prikupljamo, koristimo i štitimo vaše osobne podatke.",
      sections: {
        collection: {
          title: "Koje podatke prikupljamo",
          content: "Prikupljamo informacije koje nam dobrovoljno pružite, uključujući: ime, email adresu, broj telefona i druge podatke koje nam pošaljete putem obrazaca na web stranici."
        },
        usage: {
          title: "Kako koristimo vaše podatke",
          content: "Vaše podatke koristimo za: odgovaranje na vaše upite, slanje traženih informacija, poboljšanje naših usluga i komunikaciju o našim uslugama."
        },
        cookies: {
          title: "Kolačići",
          content: "Kolačići su male tekstualne datoteke koje se pohranjuju na vaš uređaj kada posjetite našu web stranicu. Koristimo samo funkcionalne kolačiće koji su neophodni za rad web stranice. Na primjer, kolačić za pristanak na kolačiće (cookie-consent) koji pamti vašu odluku o prihvaćanju kolačića, te kolačić za odabrani jezik (language) koji omogućava da se web stranica prikazuje na vašem odabranom jeziku. Ovi kolačići ne sadrže nikakve osobne podatke i koriste se isključivo za tehničke svrhe kako bi web stranica ispravno funkcionirala. Možete kontrolirati kolačiće kroz postavke vašeg web preglednika, a ako ih onemogućite, neke funkcionalnosti web stranice možda neće raditi ispravno."
        },
        rights: {
          title: "Vaša prava",
          content: "Imate pravo pristupiti, ispraviti ili izbrisati svoje osobne podatke. Također možete povući svoj pristanak u bilo kojem trenutku."
        },
        contact: {
          title: "Kontakt",
          content: "Za sva pitanja o privatnosti, možete nas kontaktirati na: microplet@gmail.com"
        }
      }
    },
    impressum: {
      title: "Impressum",
      company: {
        title: "Podaci o obrtu",
        name: "MICROPLET, obrt za izradu alata, preradu plastike i obradu metala, vl. Željka Barbarić",
        address: "Ante Starčevića 22",
        city: "Pleternica",
        country: "Hrvatska",
        registration: "UPRAVNI ODJEL ZA GOSPODARSTVO, ODSJEK ZA PODUZETNIŠTVO, TURIZAM I PROMET POŽEGA (POŽEGA)",
        vatId: "OIB: 46243719672",
      },
      contact: {
        title: "Kontakt podaci",
        email: "Email: microplet@gmail.com",
        phone: "Tel: +385 42 260 099",
        fax: "Fax: +385 42 260 099"
      },
      legal: {
        title: "Pravne informacije",
        content: "Sadržaj ove web stranice je zaštićen autorskim pravima. Kopiranje i distribucija sadržaja nije dozvoljena bez izričitog pristanka."
      },
      responsibility: {
        title: "Odgovornost",
        content: "Unatoč pažljivoj provjeri sadržaja, ne preuzimamo odgovornost za vanjske linkove. Za sadržaj povezanih stranica odgovorni su njihovi operatori."
      }
    }
  },
  en: {
    cookieConsent: {
      message: "We use cookies to provide you with the best experience on our website.",
      accept: "Accept all",
      decline: "Decline optional",
      settings: "Cookie settings",
      privacyLink: "Read our Privacy Policy",
      impressumLink: "Legal Notice"
    },
    privacy: {
      title: "Privacy Policy",
      lastUpdated: "Last updated: March 1, 2024",
      intro: "Your privacy is important to us. This privacy policy explains how we collect, use, and protect your personal data.",
      sections: {
        collection: {
          title: "Information We Collect",
          content: "We collect information you voluntarily provide to us, including: name, email address, phone number, and other information you submit through forms on our website."
        },
        usage: {
          title: "How We Use Your Information",
          content: "We use your information to: respond to your inquiries, send requested information, improve our services, and communicate about our services."
        },
        cookies: {
          title: "Cookies",
          content: "Cookies are small text files that are stored on your device when you visit our website. We only use functional cookies that are necessary for the website to work properly. For example, the cookie-consent cookie that remembers your choice about accepting cookies, and the language cookie that enables the website to display in your selected language. These cookies do not contain any personal information and are used solely for technical purposes to ensure the website functions correctly. You can control cookies through your browser settings, and if you disable them, some website features may not work properly."
        },
        rights: {
          title: "Your Rights",
          content: "You have the right to access, correct, or delete your personal data. You can also withdraw your consent at any time."
        },
        contact: {
          title: "Contact",
          content: "For any privacy-related questions, you can contact us at: microplet@gmail.com"
        }
      }
    },
    impressum: {
      title: "Legal Notice",
      company: {
        title: "Company Information",
        name: "MICROPLET, obrt za izradu alata, preradu plastike i obradu metala, vl. Željka Barbarić",
        address: "Ante Starčevića 22",
        city: "Pleternica",
        country: "Hrvatska",
        registration: "UPRAVNI ODJEL ZA GOSPODARSTVO, ODSJEK ZA PODUZETNIŠTVO, TURIZAM I PROMET POŽEGA (POŽEGA)",
        vatId: "OIB: 46243719672",
      },
      contact: {
        title: "Contact Information",
        email: "Email: microplet@gmail.com",
        phone: "Phone: +385 42 260 099",
        fax: "Fax: +385 42 260 099"
      },
      legal: {
        title: "Legal Information",
        content: "The content of this website is protected by copyright. Copying and distribution of content is not permitted without explicit consent."
      },
      responsibility: {
        title: "Responsibility",
        content: "Despite careful content control, we assume no liability for external links. The operators of the linked pages are responsible for their content."
      }
    }
  },
  de: {
    cookieConsent: {
      message: "Wir verwenden Cookies, um Ihnen die bestmögliche Erfahrung auf unserer Website zu bieten.",
      accept: "Alle akzeptieren",
      decline: "Optionale ablehnen",
      settings: "Cookie-Einstellungen",
      privacyLink: "Lesen Sie unsere Datenschutzerklärung",
      impressumLink: "Impressum"
    },
    privacy: {
      title: "Datenschutzerklärung",
      lastUpdated: "Zuletzt aktualisiert: 1. März 2024",
      intro: "Ihre Privatsphäre ist uns wichtig. Diese Datenschutzerklärung erläutert, wie wir Ihre personenbezogenen Daten erfassen, verwenden und schützen.",
      sections: {
        collection: {
          title: "Welche Daten wir erfassen",
          content: "Wir erfassen Informationen, die Sie uns freiwillig zur Verfügung stellen, einschließlich: Name, E-Mail-Adresse, Telefonnummer und andere Informationen, die Sie über Formulare auf unserer Website übermitteln."
        },
        usage: {
          title: "Wie wir Ihre Daten verwenden",
          content: "Wir verwenden Ihre Daten für: Beantwortung Ihrer Anfragen, Versand angeforderter Informationen, Verbesserung unserer Dienstleistungen und Kommunikation über unsere Dienstleistungen."
        },
        cookies: {
          title: "Cookies",
          content: "Cookies sind kleine Textdateien, die auf Ihrem Gerät gespeichert werden, wenn Sie unsere Website besuchen. Wir verwenden nur funktionale Cookies, die für den Betrieb der Website erforderlich sind. Zum Beispiel der cookie-consent Cookie, der Ihre Entscheidung über die Annahme von Cookies speichert, und der language Cookie, der es der Website ermöglicht, in Ihrer gewählten Sprache angezeigt zu werden. Diese Cookies enthalten keine persönlichen Informationen und werden ausschließlich für technische Zwecke verwendet, um die ordnungsgemäße Funktionsweise der Website zu gewährleisten. Sie können Cookies über Ihre Browsereinstellungen steuern, und wenn Sie sie deaktivieren, funktionieren einige Website-Funktionen möglicherweise nicht ordnungsgemäß."
        },
        rights: {
          title: "Ihre Rechte",
          content: "Sie haben das Recht, Ihre personenbezogenen Daten einzusehen, zu korrigieren oder zu löschen. Sie können Ihre Einwilligung auch jederzeit widerrufen."
        },
        contact: {
          title: "Kontakt",
          content: "Bei Fragen zum Datenschutz können Sie uns kontaktieren unter: microplet@gmail.com"
        }
      }
    },
    impressum: {
      title: "Impressum",
      company: {
        title: "Unternehmensangaben",
        name: "MICROPLET, obrt za izradu alata, preradu plastike i obradu metala, vl. Željka Barbarić",
        address: "Ante Starčevića 22",
        city: "Pleternica",
        country: "Hrvatska",
        registration: "UPRAVNI ODJEL ZA GOSPODARSTVO, ODSJEK ZA PODUZETNIŠTVO, TURIZAM I PROMET POŽEGA (POŽEGA)",
        vatId: "OIB: 46243719672",
      },
      contact: {
        title: "Kontaktinformationen",
        email: "E-Mail: microplet@gmail.com",
        phone: "Tel.: +385 42 260 099",
        fax: "Fax: +385 42 260 099"
      },
      legal: {
        title: "Rechtliche Hinweise",
        content: "Der Inhalt dieser Website ist urheberrechtlich geschützt. Das Kopieren und Verbreiten von Inhalten ist ohne ausdrückliche Zustimmung nicht gestattet."
      },
      responsibility: {
        title: "Haftung",
        content: "Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für externe Links. Für den Inhalt der verlinkten Seiten sind deren Betreiber verantwortlich."
      }
    }
  }
}; 