import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import LanguageSelector from './LanguageSelector';
import { useLanguage } from '../contexts/LanguageContext';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const { language, changeLanguage } = useLanguage();
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 20);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Prevent scrolling when mobile menu is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isOpen]);

  const navigation = {
    hr: [
      { name: 'Početna', href: '/' },
      { name: 'O Nama', href: '/about' },
      { name: 'Usluge', href: '/services' },
      { name: 'Galerija', href: '/gallery' },
      { name: 'Kontakt', href: '/contact' },
    ],
    en: [
      { name: 'Home', href: '/en' },
      { name: 'About', href: '/en/about' },
      { name: 'Services', href: '/en/services' },
      { name: 'Gallery', href: '/en/gallery' },
      { name: 'Contact', href: '/en/contact' },
    ],
    de: [
      { name: 'Startseite', href: '/de' },
      { name: 'Über Uns', href: '/de/about' },
      { name: 'Dienstleistungen', href: '/de/services' },
      { name: 'Galerie', href: '/de/gallery' },
      { name: 'Kontakt', href: '/de/contact' },
    ],
  };

  const ctaText = {
    hr: 'Zatražite Ponudu',
    en: 'Request Quote',
    de: 'Angebot Anfordern',
  };

  // Ensure we have valid navigation items for the current language
  const currentNavigation = navigation[language] || navigation.hr;
  const currentCtaText = ctaText[language] || ctaText.hr;

  // Get the correct contact link based on language
  const contactLink = language === 'hr' ? '/contact' : `/${language}/contact`;

  return (
    <nav className={`fixed w-full z-50 transition-all duration-300 ${
      scrolled ? 'bg-[#050506]/80 backdrop-blur-md' : 'bg-transparent'
    }`}>
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center h-20">
          {/* Logo */}
          <Link to={language === 'hr' ? '/' : `/${language}`} className="md:hidden flex items-center relative z-50">
            <img src="/images/microplet_bijelo.png" alt="Microplet Logo" className="h-8" />
          </Link>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center justify-between w-full">
            {/* Logo */}
            <Link to={language === 'hr' ? '/' : `/${language}`} className="flex items-center relative z-50">
              <img src="/images/microplet_bijelo.png" alt="Microplet Logo" className="h-8" />
            </Link>

            {/* Center Navigation Links */}
            <div className="flex items-center justify-center space-x-8">
              {currentNavigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className={`text-gray-300 hover:text-white transition-colors duration-300 ${
                    location.pathname === item.href ? 'text-white' : ''
                  }`}
                >
                  {item.name}
                </Link>
              ))}
              <LanguageSelector />
            </div>

            {/* Right Section - Language Selector and CTA */}
            <div className="flex items-center space-x-6">
              <Link
                to={contactLink}
                className="inline-flex items-center justify-center gap-2 px-6 py-3 text-base font-medium rounded-full bg-gradient-to-r from-laser to-[#FF8A3C] text-white transition-all duration-500 shadow-[0_0_20px_rgba(255,94,7,0.25)] hover:shadow-[0_0_25px_rgba(255,94,7,0.35)] hover:scale-[1.02]"
              >
                <span>{currentCtaText}</span>
                <span className="transform transition-transform duration-500 group-hover:translate-x-1">→</span>
              </Link>
            </div>
          </div>

          {/* Mobile Menu Button */}
          <button
            className="md:hidden relative z-50 w-10 h-10 flex items-center justify-center text-white"
            onClick={() => setIsOpen(!isOpen)}
          >
            {isOpen ? (
              <XMarkIcon className="h-8 w-8 text-white transition-all duration-300" />
            ) : (
              <Bars3Icon className="h-8 w-8 text-white transition-all duration-300" />
            )}
          </button>
        </div>

        {/* Mobile Menu */}
        <div
          className={`md:hidden fixed inset-0 z-40 transition-all duration-500 ${
            isOpen
              ? 'opacity-100 pointer-events-auto'
              : 'opacity-0 pointer-events-none'
          }`}
        >
          {/* Background Overlay with Gradient */}
          <div className="absolute inset-0 bg-[#050506]">
            {/* Top-right glow */}
            <div className="absolute top-[-20%] right-[-20%] w-[640px] h-[640px] rounded-full bg-[radial-gradient(circle,rgba(255,94,7,0.08)_0%,transparent_60%)]" />
            
            {/* Center glow */}
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[840px] h-[840px] rounded-full bg-[radial-gradient(circle,rgba(255,94,7,0.03)_0%,transparent_60%)]" />
            
            {/* Bottom-left glow */}
            <div className="absolute bottom-[-20%] left-[-20%] w-[640px] h-[640px] rounded-full bg-[radial-gradient(circle,rgba(255,94,7,0.08)_0%,transparent_60%)]" />
            
            {/* Animated gradient overlay */}
            <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_center,rgba(255,94,7,0.03)_0%,transparent_60%)] animate-pulse" />
          </div>

          {/* Menu Content */}
          <div className="relative h-screen flex flex-col items-center justify-center bg-[#050506]">
            <div className="space-y-8 text-center">
              {currentNavigation.map((item, index) => (
                <div key={item.name} className="overflow-hidden">
                  <Link
                    to={item.href}
                    className="block text-[24px] text-white hover:text-laser transition-all duration-300 transform hover:translate-x-2"
                    onClick={() => setIsOpen(false)}
                    style={{
                      transitionDelay: `${index * 100}ms`,
                      opacity: isOpen ? 1 : 0,
                      transform: isOpen ? 'translateY(0)' : 'translateY(20px)'
                    }}
                  >
                    {item.name}
                  </Link>
                </div>
              ))}
              {/* Mobile Language Selector */}
              <div className="flex items-center justify-center space-x-4 pt-4">
                <button
                  onClick={() => {
                    changeLanguage('hr');
                    setIsOpen(false);
                  }}
                  className={`text-lg font-medium ${
                    language === 'hr' ? 'text-laser' : 'text-gray-300 hover:text-white'
                  } transition-colors duration-300`}
                >
                  HR
                </button>
                <span className="text-gray-500">|</span>
                <button
                  onClick={() => {
                    changeLanguage('en');
                    setIsOpen(false);
                  }}
                  className={`text-lg font-medium ${
                    language === 'en' ? 'text-laser' : 'text-gray-300 hover:text-white'
                  } transition-colors duration-300`}
                >
                  EN
                </button>
                <span className="text-gray-500">|</span>
                <button
                  onClick={() => {
                    changeLanguage('de');
                    setIsOpen(false);
                  }}
                  className={`text-lg font-medium ${
                    language === 'de' ? 'text-laser' : 'text-gray-300 hover:text-white'
                  } transition-colors duration-300`}
                >
                  DE
                </button>
              </div>

              {/* CTA Button */}
              <Link
                to={contactLink}
                className="inline-flex items-center justify-center gap-2 px-6 py-3 text-base font-medium rounded-full bg-gradient-to-r from-laser to-[#FF8A3C] text-white transition-all duration-500 shadow-[0_0_20px_rgba(255,94,7,0.25)] hover:shadow-[0_0_25px_rgba(255,94,7,0.35)] hover:scale-[1.02] mt-8"
                onClick={() => setIsOpen(false)}
              >
                <span>{currentCtaText}</span>
                <span className="transform transition-transform duration-500 group-hover:translate-x-1">→</span>
              </Link>
              {/* <LanguageSelector isMobile={true} /> */}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar; 