import React, { useState, useEffect } from 'react';
import { useLanguage } from '../contexts/LanguageContext';

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { language, t } = useLanguage();

  useEffect(() => {
    const consent = localStorage.getItem('cookie-consent');
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookie-consent', 'accepted');
    setIsVisible(false);
  };

  const handleDecline = () => {
    localStorage.setItem('cookie-consent', 'declined');
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-dark-900 text-white p-4 z-50 border-t border-dark-700">
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-between gap-4">
        <div className="flex-1">
          <p className="text-sm text-gray-300">
            {t.cookieConsent?.message || 'We use cookies to improve your experience on our website.'}
          </p>
        </div>
        <div className="flex flex-col sm:flex-row gap-2">
          <button
            onClick={handleAccept}
            className="px-4 py-2 bg-gradient-to-r from-laser to-[#FF8A3C] hover:from-[#FF8A3C] hover:to-laser rounded-lg text-sm font-medium transition-all duration-300 hover:shadow-[0_0_15px_rgba(255,94,7,0.35)]"
          >
            {t.cookieConsent?.accept || 'Accept'}
          </button>
          <button
            onClick={handleDecline}
            className="px-4 py-2 bg-dark-800 hover:bg-dark-700 rounded-lg text-sm font-medium transition-colors"
          >
            {t.cookieConsent?.decline || 'Decline'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent; 