import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { translations as homeTranslations } from '../translations/home';
import { translations as legalTranslations } from '../translations/legal';

const LanguageContext = createContext();

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};

export const LanguageProvider = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [language, setLanguage] = useState('hr');

  // Function to get the base path without language prefix
  const getBasePath = (path) => {
    return path.replace(/^\/(en|de)\//, '/').replace(/^\/(en|de)$/, '/');
  };

  useEffect(() => {
    const path = location.pathname;
    if (path.startsWith('/en')) {
      setLanguage('en');
    } else if (path.startsWith('/de')) {
      setLanguage('de');
    } else {
      setLanguage('hr');
    }
  }, [location]);

  const changeLanguage = (newLang) => {
    const currentPath = location.pathname;
    const basePath = getBasePath(currentPath);
    
    // Construct new path based on language
    let newPath;
    if (newLang === 'hr') {
      newPath = basePath;
    } else {
      // Handle root path special case
      if (basePath === '/') {
        newPath = `/${newLang}`;
      } else {
        newPath = `/${newLang}${basePath}`;
      }
    }

    // Update language state and navigate
    setLanguage(newLang);
    navigate(newPath, { replace: true });
  };

  const t = {
    ...homeTranslations[language],
    ...legalTranslations[language],
    cookieConsent: legalTranslations[language].cookieConsent
  };

  return (
    <LanguageContext.Provider value={{ language, setLanguage, changeLanguage, t }}>
      {children}
    </LanguageContext.Provider>
  );
}; 