import React, { useState, useEffect, useRef } from 'react';
import { useLanguage } from '../contexts/LanguageContext';
import { translations } from '../translations/home';
import Transition from '../components/Transition';
import SEO from '../components/SEO';
import { XMarkIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';

const ITEMS_PER_PAGE = 12;

const Gallery = () => {
  const { language } = useLanguage();
  const t = translations[language];
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [filteredItems, setFilteredItems] = useState([]);
  const [categoryImages, setCategoryImages] = useState({});
  const [lightboxImage, setLightboxImage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const galleryRef = useRef(null);
  const galleryGridRef = useRef(null);

  // Define categories with their translations
  const categories = {
    all: {
      hr: 'Sve',
      en: 'All',
      de: 'Alle'
    },
    laser: {
      hr: 'Laser',
      en: 'Laser',
      de: 'Laser'
    },
    tokarenje: {
      hr: 'Tokarenje',
      en: 'Turning',
      de: 'Drehen'
    },
    alati: {
      hr: 'Alati',
      en: 'Tools',
      de: 'Werkzeuge'
    },
    glodalica: {
      hr: 'Glodalica',
      en: 'Milling',
      de: 'Fräsmaschine'
    },
    savijanje: {
      hr: 'Savijanje',
      en: 'Bending',
      de: 'Biegen'
    },
    brizganje: {
      hr: 'Brizganje',
      en: 'Injection',
      de: 'Spritzguss'
    },
    rezanje: {
      hr: 'Rezanje',
      en: 'Cutting',
      de: 'Schneiden'
    },
    varenje: {
      hr: 'Varenje',
      en: 'Welding',
      de: 'Schweißen'
    }
  };

  // Predefined gallery items with exact paths
  const galleryItems = [
    // Laser
    { id: 1, category: 'laser', src: '/images/Laser/l1.jpeg' },
    { id: 2, category: 'laser', src: '/images/Laser/l2.jpeg' },
    { id: 3, category: 'laser', src: '/images/Laser/l3.jpeg' },
    { id: 4, category: 'laser', src: '/images/Laser/l4.jpeg' },
    { id: 5, category: 'laser', src: '/images/Laser/l5.jpeg' },
    { id: 6, category: 'laser', src: '/images/Laser/l6.jpeg' },
    { id: 7, category: 'laser', src: '/images/Laser/l7.jpeg' },
    { id: 8, category: 'laser', src: '/images/Laser/l8.jpeg' },
    
    // Tokarenje
    { id: 9, category: 'tokarenje', src: '/images/Tokarenje/t1.jpeg' },
    { id: 10, category: 'tokarenje', src: '/images/Tokarenje/t2.jpeg' },
    { id: 11, category: 'tokarenje', src: '/images/Tokarenje/t3.jpeg' },
    { id: 12, category: 'tokarenje', src: '/images/Tokarenje/t4.jpeg' },
    { id: 13, category: 'tokarenje', src: '/images/Tokarenje/t5.jpeg' },
    { id: 14, category: 'tokarenje', src: '/images/Tokarenje/t6.jpeg' },
    { id: 15, category: 'tokarenje', src: '/images/Tokarenje/t7.jpeg' },
    
    // Alati
    { id: 16, category: 'alati', src: '/images/Alati/a1.jpeg' },
    { id: 17, category: 'alati', src: '/images/Alati/a2.jpeg' },
    { id: 18, category: 'alati', src: '/images/Alati/a3.jpeg' },
    { id: 19, category: 'alati', src: '/images/Alati/a4.jpeg' },
    { id: 20, category: 'alati', src: '/images/Alati/a5.jpeg' },
    { id: 21, category: 'alati', src: '/images/Alati/a6.jpeg' },
    { id: 22, category: 'alati', src: '/images/Alati/a7.jpeg' },
    
    // Glodalica
    { id: 23, category: 'glodalica', src: '/images/Glodalica/g1.jpeg' },
    { id: 24, category: 'glodalica', src: '/images/Glodalica/g2.jpeg' },
    
    // Savijanje
    { id: 25, category: 'savijanje', src: '/images/Savijanje/s1.jpeg' },
    { id: 26, category: 'savijanje', src: '/images/Savijanje/s2.jpeg' },
    { id: 27, category: 'savijanje', src: '/images/Savijanje/s3.jpeg' },
    { id: 28, category: 'savijanje', src: '/images/Savijanje/s4.jpeg' },
    { id: 29, category: 'savijanje', src: '/images/Savijanje/s5.jpeg' },
    
    // Brizganje
    { id: 30, category: 'brizganje', src: '/images/Brizganje/b1.jpeg' },
    { id: 31, category: 'brizganje', src: '/images/Brizganje/b2.jpeg' },
    
    // Rezanje
    { id: 32, category: 'rezanje', src: '/images/Rezanje/r1.jpeg' },
    { id: 33, category: 'rezanje', src: '/images/Rezanje/r2.jpeg' },
    { id: 34, category: 'rezanje', src: '/images/Rezanje/r3.jpeg' },
    
    // Varenje
    { id: 35, category: 'varenje', src: '/images/Varenje/v1.jpeg' },
    { id: 36, category: 'varenje', src: '/images/Varenje/v2.jpeg' },
    { id: 37, category: 'varenje', src: '/images/Varenje/v3.jpeg' },
    { id: 38, category: 'varenje', src: '/images/Varenje/v4.jpeg' },
    { id: 39, category: 'varenje', src: '/images/Varenje/v5.jpeg' },
    { id: 40, category: 'varenje', src: '/images/Varenje/v6.jpeg' },
  ];

  // Initialize filtered items and category counts
  useEffect(() => {
    const filtered = selectedCategory === 'all'
      ? galleryItems
      : galleryItems.filter(item => item.category === selectedCategory);
    
    setFilteredItems(filtered);

    // Calculate category counts
    const counts = galleryItems.reduce((acc, item) => {
      acc[item.category] = (acc[item.category] || 0) + 1;
      return acc;
    }, {});
    setCategoryImages(counts);
  }, [selectedCategory]);

  // Calculate pagination
  const totalPages = Math.ceil(filteredItems.length / ITEMS_PER_PAGE);
  const paginatedItems = filteredItems.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  // Reset to first page when category changes
  useEffect(() => {
    setCurrentPage(1);
  }, [selectedCategory]);

  // Function to handle lightbox navigation
  const handleLightboxNavigation = (direction) => {
    const currentIndex = filteredItems.findIndex(item => item.id === lightboxImage.id);
    let newIndex;

    if (direction === 'next') {
      newIndex = currentIndex + 1 >= filteredItems.length ? 0 : currentIndex + 1;
    } else {
      newIndex = currentIndex - 1 < 0 ? filteredItems.length - 1 : currentIndex - 1;
    }

    setLightboxImage(filteredItems[newIndex]);
  };

  // Handle keyboard navigation
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (!lightboxImage) return;

      if (e.key === 'Escape') {
        setLightboxImage(null);
      } else if (e.key === 'ArrowRight') {
        handleLightboxNavigation('next');
      } else if (e.key === 'ArrowLeft') {
        handleLightboxNavigation('prev');
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [lightboxImage, filteredItems]);

  // Handle page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    // Add offset to account for header/navigation
    const yOffset = -100; 
    const element = galleryGridRef.current;
    const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  };

  const seoData = {
    hr: {
      title: 'Microplet - Galerija Projekata',
      description: 'Pogledajte našu galeriju projekata laserskog rezanja metala, izrade alata i prerade plastike. Primjeri našeg rada i stručnosti.'
    },
    en: {
      title: 'Microplet - Project Gallery',
      description: 'View our gallery of metal laser cutting, tool manufacturing, and plastic processing projects. Examples of our work and expertise.'
    },
    de: {
      title: 'Microplet - Projektgalerie',
      description: 'Sehen Sie unsere Galerie von Projekten für Metall-Laserschneiden, Werkzeugherstellung und Kunststoffverarbeitung. Beispiele unserer Arbeit und Expertise.'
    }
  };

  return (
    <>
      <SEO {...seoData[language]} />
      <div className="min-h-screen bg-dark-900">
        {/* Background Effects */}
        <div className="fixed inset-0 z-0">
          {/* Animated gradient circles */}
          <div className="absolute top-0 right-0 w-[840px] h-[840px] bg-[radial-gradient(circle_at_center,rgba(255,94,7,0.12)_0%,transparent_70%)] md:bg-[radial-gradient(circle_at_center,rgba(255,94,7,0.12)_0%,transparent_70%)] rounded-full animate-pulse"></div>
          <div className="absolute bottom-0 left-0 w-[1040px] h-[1040px] bg-[radial-gradient(circle_at_center,rgba(255,94,7,0.03)_0%,transparent_70%)] md:bg-[radial-gradient(circle_at_center,rgba(255,94,7,0.08)_0%,transparent_70%)] rounded-full animate-pulse delay-1000"></div>
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[840px] h-[840px] bg-[radial-gradient(circle_at_center,rgba(255,94,7,0.05)_0%,transparent_70%)] md:bg-[radial-gradient(circle_at_center,rgba(255,94,7,0.1)_0%,transparent_70%)] rounded-full animate-pulse delay-500"></div>
          
          {/* Animated gradient overlay */}
          <div className="absolute inset-0 bg-gradient-to-b from-transparent via-dark-900/50 to-dark-900 md:via-dark-900/30 animate-pulse"></div>
        </div>
        {/* Hero Section */}
        <section className="relative pt-60 pb-52 overflow-hidden">
          
          {/* Content */}
          <div className="relative container mx-auto px-4">
            <div className="max-w-4xl mx-auto text-center">
              <Transition delay={0.4}>
                <h1 className="text-4xl md:text-6xl font-bold mb-6">
                  <span className="block pb-2 text-[#d6d6d6] [-webkit-text-fill-color:transparent] bg-[linear-gradient(270deg,#36383c,#fff_30%,#36383c)] [-webkit-background-clip:text] bg-clip-text">
                    {t.gallery.title}
                  </span>
                </h1>
                <p className="text-xl text-gray-400 mb-8">
                    {t.gallery.description}
                </p>
              </Transition>
            </div>
          </div>
        </section>

        {/* Main Gallery Section */}
        <section className="relative pb-32 overflow-hidden">
          <div className="relative container mx-auto px-4">
            <div className="max-w-4xl mx-auto text-center" ref={galleryRef}>
              {/* Category Filter */}
              <Transition delay={0.6}>
                <div className="flex flex-wrap justify-center gap-3 mb-12">
                  {Object.entries(categories).map(([key, value]) => (
                    <button
                      key={key}
                      onClick={() => setSelectedCategory(key)}
                      className={`px-4 py-2 rounded-full text-sm font-medium transition-all duration-300 ${
                        selectedCategory === key
                        ? 'bg-gradient-to-r from-laser to-[#FF8A3C] text-white shadow-[0_0_20px_rgba(255,94,7,0.25)]'
                        : 'text-gray-400 hover:text-white bg-[#0a0a0b] hover:bg-[#151516]'
                      }`}
                    >
                      {value[language]}
                      {key !== 'all' && categoryImages[key] > 0 && (
                        <span className="ml-2 text-xs opacity-60">({categoryImages[key]})</span>
                      )}
                    </button>
                  ))}
                </div>
              </Transition>
            </div>

            {/* Gallery Grid */}
            <div ref={galleryGridRef} className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {paginatedItems.map((item, index) => (
                <Transition key={item.id} delay={0.2 + (index % 6) * 0.1}>
                  <div 
                    className="group relative overflow-hidden rounded-3xl bg-[#0a0a0b] hover:bg-[#151516] transition-all duration-500 cursor-pointer"
                    onClick={() => setLightboxImage(item)}
                  >
                    <div className="w-full h-[300px]">
                      <img
                        src={item.src}
                        alt={categories[item.category][language]}
                        className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-500"
                      />
                    </div>
                  </div>
                </Transition>
              ))}
            </div>

            {/* Pagination */}
            {totalPages > 1 && (
              <div className="mt-12 flex justify-center items-center gap-2">
                <button
                  onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}
                  disabled={currentPage === 1}
                  className={`p-2 rounded-full transition-all duration-300 ${
                    currentPage === 1
                    ? 'text-gray-600 cursor-not-allowed'
                    : 'text-white hover:text-laser'
                  }`}
                >
                  <ChevronLeftIcon className="w-6 h-6" />
                </button>

                {[...Array(totalPages)].map((_, index) => {
                  const pageNumber = index + 1;
                  const isCurrentPage = pageNumber === currentPage;
                  const isNearCurrent = Math.abs(pageNumber - currentPage) <= 1;
                  const isFirstOrLast = pageNumber === 1 || pageNumber === totalPages;

                  if (!isNearCurrent && !isFirstOrLast) {
                    if (pageNumber === 2 || pageNumber === totalPages - 1) {
                      return <span key={pageNumber} className="text-gray-600">...</span>;
                    }
                    return null;
                  }

                  return (
                    <button
                      key={pageNumber}
                      onClick={() => handlePageChange(pageNumber)}
                      className={`w-8 h-8 rounded-full text-sm font-medium transition-all duration-300 ${
                        isCurrentPage
                        ? 'bg-gradient-to-r from-laser to-[#FF8A3C] text-white shadow-[0_0_20px_rgba(255,94,7,0.25)]'
                        : 'text-gray-400 hover:text-white bg-[#0a0a0b] hover:bg-[#151516]'
                      }`}
                    >
                      {pageNumber}
                    </button>
                  );
                })}

                <button
                  onClick={() => handlePageChange(Math.min(currentPage + 1, totalPages))}
                  disabled={currentPage === totalPages}
                  className={`p-2 rounded-full transition-all duration-300 ${
                    currentPage === totalPages
                    ? 'text-gray-600 cursor-not-allowed'
                    : 'text-white hover:text-laser'
                  }`}
                >
                  <ChevronRightIcon className="w-6 h-6" />
                </button>
              </div>
            )}
          </div>
        </section>

        {/* Lightbox */}
        {lightboxImage && (
          <div 
            className="fixed inset-0 z-50 bg-black/90 flex items-center justify-center"
            onClick={() => setLightboxImage(null)}
          >
            {/* Close button */}
            <button 
              className="absolute top-4 right-4 text-white hover:text-laser transition-colors z-50"
              onClick={() => setLightboxImage(null)}
            >
              <XMarkIcon className="w-8 h-8" />
            </button>

            {/* Navigation buttons */}
            <button 
              className="absolute left-4 text-white hover:text-laser transition-colors z-50"
              onClick={(e) => {
                e.stopPropagation();
                handleLightboxNavigation('prev');
              }}
            >
              <ChevronLeftIcon className="w-8 h-8" />
            </button>

            <button 
              className="absolute right-4 text-white hover:text-laser transition-colors z-50"
              onClick={(e) => {
                e.stopPropagation();
                handleLightboxNavigation('next');
              }}
            >
              <ChevronRightIcon className="w-8 h-8" />
            </button>

            {/* Image container */}
            <div 
              className="relative max-w-[90vw] max-h-[90vh]"
              onClick={(e) => e.stopPropagation()}
            >
              <img
                src={lightboxImage.src}
                alt={categories[lightboxImage.category][language]}
                className="max-w-full max-h-[90vh] object-contain"
              />
              
              {/* Image counter */}
              <div className="absolute bottom-4 left-1/2 -translate-x-1/2 text-white text-sm bg-black/50 px-4 py-2 rounded-full">
                {filteredItems.findIndex(item => item.id === lightboxImage.id) + 1} / {filteredItems.length}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Gallery; 