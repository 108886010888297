import React from 'react';
import { Link } from 'react-router-dom';
import { useLanguage } from '../contexts/LanguageContext';
import { translations } from '../translations/contact';
const Footer = () => {
  const { language } = useLanguage();
  const t = translations[language];
  const getNavigation = () => {
    switch (language) {
      case 'en':
        return {
          main: [
            { name: 'Home', href: '/en' },
            { name: 'About', href: '/en/about' },
            { name: 'Services', href: '/en/services' },
            { name: 'Contact', href: '/en/contact' },
          ],
          legal: [
            { name: 'Privacy Policy', href: '/en/privacy-policy' },
            { name: 'Legal Notice', href: '/en/impressum' },
          ],
        };
      case 'de':
        return {
          main: [
            { name: 'Startseite', href: '/de' },
            { name: 'Über uns', href: '/de/about' },
            { name: 'Dienstleistungen', href: '/de/services' },
            { name: 'Kontakt', href: '/de/contact' },
          ],
          legal: [
            { name: 'Datenschutz', href: '/de/privacy-policy' },
            { name: 'Impressum', href: '/de/impressum' },
          ],
        };
      default: // Croatian
        return {
          main: [
            { name: 'Početna', href: '/' },
            { name: 'O nama', href: '/about' },
            { name: 'Usluge', href: '/services' },
            { name: 'Kontakt', href: '/contact' },
          ],
          legal: [
            { name: 'Politika privatnosti', href: '/privacy-policy' },
            { name: 'Impressum', href: '/impressum' },
          ],
        };
    }
  };

  const navigation = getNavigation();

  return (
    <footer className="bg-dark-900 opacity-90 border-t border-dark-700">
      <div className="container mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Company Info */}
          <div className="space-y-8">
            <Link to="/" className="flex items-center">
              <img src="/images/microplet_bijelo.png" alt="Microplet Logo" className="h-8" />
            </Link>
            <p className="text-gray-400 text-sm max-w-md">
              {language === 'de' 
                ? 'Ihre Experten für Laserzuschnitt, Werkzeugherstellung und Kunststoffverarbeitung.'
                : language === 'en'
                ? 'Your experts in laser cutting, tool manufacturing, and plastic processing.'
                : 'Vaši stručnjaci za laserski rez, izradu alata i preradu plastike.'}
            </p>
          </div>

          {/* Main Navigation */}
          <div>
            <h3 className="text-sm font-semibold text-gray-400 uppercase tracking-wider">
              {language === 'de' ? 'Navigation' : language === 'en' ? 'Navigation' : 'Navigacija'}
            </h3>
            <ul className="mt-4 space-y-4">
              {navigation.main.map((item) => (
                <li key={item.name}>
                  <Link
                    to={item.href}
                    className="text-gray-300 hover:text-white transition-colors"
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Contact Info */}
          <div>
            <h3 className="text-sm font-semibold text-gray-400 uppercase tracking-wider">
              {language === 'de' ? 'Kontakt' : language === 'en' ? 'Contact' : 'Kontakt'}
            </h3>
            <ul className="mt-4 space-y-4">
              <li className="text-gray-300">
                <p>{t.info.address.street}</p>
                <p>{t.info.address.city}, {t.info.address.country}</p>
                <p><a href="mailto:microplet@gmail.com" className="mt-2">{t.info.email}: microplet@gmail.com</a></p>
              </li>
            </ul>
          </div>

          {/* Legal Links */}
          <div>
            <h3 className="text-sm font-semibold text-gray-400 uppercase tracking-wider">
              {language === 'de' ? 'Rechtliches' : language === 'en' ? 'Legal' : 'Pravno'}
            </h3>
            <ul className="mt-4 space-y-4">
              {navigation.legal.map((item) => (
                <li key={item.name}>
                  <Link
                    to={item.href}
                    className="text-gray-300 hover:text-white transition-colors"
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="mt-12 border-t border-dark-700 pt-8">
          <p className="text-center text-gray-400">
            © {new Date().getFullYear()} Microplet - {language === 'de' ? 'Alle Rechte vorbehalten' : language === 'en' ? 'All rights reserved' : 'Sva prava pridržana'}. 
            <span className="ml-2">
              {language === 'de' ? 'Website erstellt von' : language === 'en' ? 'Designed & Developed by' : 'Dizajn i razvoj:'}{' '}
              <a 
                href="http://mpcode-solutions.com/" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-laser hover:text-[#FF8A3C] transition-colors"
              >
                MP CODE
              </a>
            </span>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer; 