import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { LanguageProvider } from './contexts/LanguageContext';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import CookieConsent from './components/CookieConsent';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Contact from './pages/Contact';
import Gallery from './pages/Gallery';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Impressum from './pages/Impressum';

function App() {
  return (
    <HelmetProvider>
      <Router>
        <LanguageProvider>
          <div className="flex flex-col min-h-screen">
            <ScrollToTop />
            <Navbar />
            <main className="flex-grow">
              <Routes>
                {/* Croatian routes (no prefix) */}
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/services" element={<Services />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/gallery" element={<Gallery />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/impressum" element={<Impressum />} />

                {/* English routes */}
                <Route path="/en" element={<Home />} />
                <Route path="/en/about" element={<About />} />
                <Route path="/en/services" element={<Services />} />
                <Route path="/en/contact" element={<Contact />} />
                <Route path="/en/gallery" element={<Gallery />} />
                <Route path="/en/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/en/impressum" element={<Impressum />} />

                {/* German routes */}
                <Route path="/de" element={<Home />} />
                <Route path="/de/about" element={<About />} />
                <Route path="/de/services" element={<Services />} />
                <Route path="/de/contact" element={<Contact />} />
                <Route path="/de/gallery" element={<Gallery />} />
                <Route path="/de/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/de/impressum" element={<Impressum />} />

                {/* Catch-all route - redirect to home */}
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </main>
            <Footer />
            <CookieConsent />
          </div>
        </LanguageProvider>
      </Router>
    </HelmetProvider>
  );
}

export default App;
