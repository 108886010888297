import React, { useRef, useEffect, useState } from 'react';
import { useLanguage } from '../contexts/LanguageContext';
import { translations } from '../translations/home';
import Transition from './Transition';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';

const Partners = () => {
  const { language } = useLanguage();
  const t = translations[language];
  const scrollContainerRef = useRef(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const partners = [
    { name: 'Đuro Đaković Specijalna vozila', logo: '/images/Partneri/dd-sv.png' },
    { name: 'Andritz Tep', logo: '/images/Partneri/andritz.png' },
    { name: 'Đuro Đaković Strojna obrada', logo: '/images/Partneri/dd-so.png' },
    { name: 'Commel', logo: '/images/Partneri/commel.png' },
    { name: 'Selk', logo: '/images/Partneri/selk.png' },
    { name: 'Palmex d.o.o.', logo: '' },
    { name: 'IMPAL d.o.o.', logo: '' },
    { name: 'Thermia', logo: '/images/Partneri/thermia.png' },
    { name: 'Plamen', logo: '/images/Partneri/plamen.png' },
    { name: 'Zvečevo', logo: '/images/Partneri/zvecevo.png' },
    { name: 'Color trgovina', logo: '/images/Partneri/color.png' },
    { name: 'Ergo klamerice', logo: '/images/Partneri/ergo.png' },
    { name: 'Altrad - Limex', logo: '/images/Partneri/altrad-limex.png' },
    { name: 'Rotter und Rudiger und Partner GmbH', logo: '/images/Partneri/r&r.png' },
    { name: 'Stasim Tecnica s.r.l., Italy', logo: '' },
    { name: 'Spiroflex', logo: '/images/Partneri/spiroflex.png' },
    { name: 'Metal Pres', logo: '/images/Partneri/metalpres.png' },
    { name: 'IN - metal j.d.o.o.', logo: '' },
    { name: 'Windor', logo: '/images/Partneri/windor.png' },
    { name: 'Lignor', logo: '/images/Partneri/lignor.png' },
    { name: 'Parketi arambašić', logo: '/images/Partneri/parketi-arambasic.png' },
    { name: 'Laser inženjering', logo: '/images/Partneri/Laser-Ing.png' },
    { name: 'Gotika', logo: '/images/Partneri/gotika.png' },
    { name: 'Tomani d.o.o.', logo: '' },
    { name: 'Pleter Trade', logo: '/images/Partneri/pleter-trade.png' }
  ];

  // Check if device is mobile
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  // Handle scroll position for arrows
  const handleScroll = () => {
    const container = scrollContainerRef.current;
    if (!container) return;

    const isStart = container.scrollLeft === 0;
    const isEnd = container.scrollLeft + container.clientWidth >= container.scrollWidth - 1;
    
    setShowLeftArrow(!isStart);
    setShowRightArrow(!isEnd);
  };

  // Add scroll position detection
  useEffect(() => {
    const container = scrollContainerRef.current;
    if (!container) return;

    container.addEventListener('scroll', handleScroll);
    return () => container.removeEventListener('scroll', handleScroll);
  }, []);

  // Drag functionality for desktop
  const handleMouseDown = (e) => {
    if (isMobile) return;
    setIsDragging(true);
    setStartX(e.pageX - scrollContainerRef.current.offsetLeft);
    setScrollLeft(scrollContainerRef.current.scrollLeft);
  };

  const handleMouseUp = () => {
    if (isMobile) return;
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (!isDragging || isMobile) return;
    e.preventDefault();
    const x = e.pageX - scrollContainerRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    scrollContainerRef.current.scrollLeft = scrollLeft - walk;
  };

  // Auto-scroll functionality - only on desktop
  useEffect(() => {
    const container = scrollContainerRef.current;
    if (!container || isMobile) return;

    let scrollInterval;
    let isHovered = false;
    let isResetting = false;

    const startAutoScroll = () => {
      scrollInterval = setInterval(() => {
        if (!isHovered && !isDragging && !isResetting) {
          if (container.scrollLeft >= container.scrollWidth - container.clientWidth - 1) {
            isResetting = true;
            container.scrollTo({ left: 0, behavior: 'smooth' });
            // Wait for smooth scroll to complete before resuming
            setTimeout(() => {
              isResetting = false;
            }, 500);
          } else {
            container.scrollLeft += 1;
          }
        }
      }, 30);
    };

    container.addEventListener('mouseenter', () => {
      isHovered = true;
    });

    container.addEventListener('mouseleave', () => {
      isHovered = false;
    });

    startAutoScroll();

    return () => {
      clearInterval(scrollInterval);
    };
  }, [isMobile, isDragging]);

  // Mobile scroll functions
  const scrollLeftMobile = (e) => {
    e.stopPropagation();
    const container = scrollContainerRef.current;
    if (container) {
      container.scrollBy({ left: -300, behavior: 'smooth' });
    }
  };

  const scrollRightMobile = (e) => {
    e.stopPropagation();
    const container = scrollContainerRef.current;
    if (container) {
      container.scrollBy({ left: 300, behavior: 'smooth' });
    }
  };

  return (
    <section className="relative py-20 overflow-hidden bg-[#050506]">
      {/* Background gradient */}
      {/* <div className="absolute inset-0 z-0">
        <div className="absolute top-0 left-0 w-full h-full bg-[linear-gradient(180deg,rgba(18,18,18,0)_0%,#121212_100%)]"></div>
      </div> */}

      <div className="relative container mx-auto px-4">
        <div className="max-w-4xl mx-auto text-center mb-12">
          <Transition delay={0.2}>
            <span className="inline-block text-laser text-sm font-medium tracking-wider mb-4">
              {t.partners?.subtitle || 'Our Partners'}
            </span>
          </Transition>
          <Transition delay={0.4}>
            <h2 className="text-3xl md:text-4xl font-bold">
              <span className="block pb-2 text-[#d6d6d6] [-webkit-text-fill-color:transparent] bg-[linear-gradient(270deg,#36383c,#fff_54%,#36383c)] [-webkit-background-clip:text] bg-clip-text">
                {t.partners?.title || 'Trusted by Industry Leaders'}
              </span>
            </h2>
          </Transition>
        </div>

        {/* Partners list with gradient fade edges and navigation arrows */}
        <div className="relative">
          {/* Left fade and arrow - only on mobile */}
          <div className="absolute left-0 top-0 bottom-0 w-32 z-[1] bg-gradient-to-r from-[#050506] to-transparent pointer-events-none"></div>
          {isMobile && showLeftArrow && (
            <button
              onClick={scrollLeftMobile}
              className="absolute left-4 top-1/2 -translate-y-1/2 z-30 p-2 rounded-full bg-[#0a0a0b]/80 text-white hover:text-laser transition-colors duration-300 cursor-pointer hover:bg-[#151516]"
              aria-label="Scroll left"
            >
              <ChevronLeftIcon className="w-6 h-6" />
            </button>
          )}
          
          {/* Right fade and arrow - only on mobile */}
          <div className="absolute right-0 top-0 bottom-0 w-32 z-[1] bg-gradient-to-l from-[#050506] to-transparent pointer-events-none"></div>
          {isMobile && showRightArrow && (
            <button
              onClick={scrollRightMobile}
              className="absolute right-4 top-1/2 -translate-y-1/2 z-30 p-2 rounded-full bg-[#0a0a0b]/80 text-white hover:text-laser transition-colors duration-300 cursor-pointer hover:bg-[#151516]"
              aria-label="Scroll right"
            >
              <ChevronRightIcon className="w-6 h-6" />
            </button>
          )}
          
          {/* Scrollable container with drag functionality */}
          <div 
            ref={scrollContainerRef}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
            onMouseMove={handleMouseMove}
            className={`relative flex gap-8 overflow-x-auto scrollbar-hide py-8 touch-pan-x z-[2] bg-[#050506] select-none ${!isMobile ? 'cursor-grab active:cursor-grabbing' : ''}`}
            style={{ 
              scrollBehavior: isDragging ? 'auto' : 'smooth',
              WebkitOverflowScrolling: 'touch',
              msOverflowStyle: 'none',
              scrollbarWidth: 'none'
            }}
          >
            {partners.map((partner, index) => (
              <Transition key={partner.name} delay={0.2 + (index % 6) * 0.1}>
                <div className="flex-none group select-none">
                  {partner.logo ? (
                    // If logo is available
                    <div className="w-48 h-24 bg-[#0a0a0b] rounded-2xl overflow-hidden flex items-center justify-center p-4 transition-all duration-300 group-hover:bg-[#151516] select-none">
                      <img 
                        src={partner.logo} 
                        alt={partner.name}
                        className="max-w-full max-h-full object-contain opacity-70 transition-opacity duration-300 group-hover:opacity-100 select-none pointer-events-none"
                        draggable="false"
                      />
                    </div>
                  ) : (
                    // If no logo, show name
                    <div className="w-48 h-24 bg-[#0a0a0b] rounded-2xl overflow-hidden flex items-center justify-center p-4 transition-all duration-300 group-hover:bg-[#151516] select-none">
                      <span className="text-center text-md text-gray-400 group-hover:text-white transition-colors duration-300 select-none">
                        {partner.name}
                      </span>
                    </div>
                  )}
                </div>
              </Transition>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Partners; 